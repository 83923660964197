import * as React from "react"
import MainContainer from "../components/MainContainer"
import MiscWrapper from "../components/MiscWrapper"
import Title from "../components/misc/Title"
import Paragraph from "../components/misc/Paragraph"
import styled from "@emotion/styled"
import Separator from "../components/misc/Separator"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/SEO"

const Link = styled.a({
    color: "#000",
    textDecoration: "underline"
})

export default function AtributionsPage() {

    const { seoData } = useStaticQuery(query)
    const { seoTitle, seoDescription } = seoData

    return (
        <MainContainer>
            <SEO title={seoTitle} description={seoDescription} pagename="atributions" />
            <MiscWrapper>
                <Title>Atribuciones</Title>
                <Paragraph>
                    Imagen de fondo: <Link href='https://www.freepik.es/vectores/fondo-abstracto'>Vector de Fondo abstracto creado por rawpixel.com - www.freepik.es</Link>
                    <br/>
                    Error 404: Iconos diseñados por <Link href="https://www.freepik.com" title="Freepik">Freepik</Link> from <Link href="https://www.flaticon.es/" title="Flaticon">www.flaticon.es</Link>
                </Paragraph>
            </MiscWrapper>
            <Separator height="45vh" />
        </MainContainer>
    )
}

const query = graphql`
    query {
        seoData: headersJson(id: {eq: "atributions"}) {
            seoTitle
            seoDescription
        }
    }
`
