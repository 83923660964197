import styled from "@emotion/styled"

export default styled.div({
    backgroundColor: "rgba(240, 240, 240, 0.9)",
    backdropFilter: "blur(5px)",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingTop: "2.1em",
    paddingBottom: "3em"
})
